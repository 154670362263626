/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import toast from 'react-hot-toast';
import Loading from '@/components/atoms/Loading';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import MainTemplate from '@/components/templates/MainTemplate';
import TransactionResume from '@/components/molecules/TransactionResume';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { ReactComponent as FilterIcon } from '@/assets/filterIcon.svg';
import { ReactComponent as DownloadIcon } from '@/assets/downloadIcon.svg';
import { Paragraph } from '@/components/atoms/Typography/styles';
import {
  downloadTransactionsPdf,
  listMerchants,
  listTransactions,
} from '@/services/api';

import { PaginationWrapper } from './styles';
import TwTitle from '@/components/atoms/TwTitle';
import { extractErrorSlug } from '@/helpers/functions';
import useFilter from '@/hooks/useFilter';
import Filters from '@/components/organisms/Filters';

const ITEMS_PER_PAGE = 10;
const ITEMS_PER_PAGE_MOBILE = 10;
const NUM_OF_PAGES_DEFAULT = 2;

const getLastMonthRange = () => {
  const endDate = new Date();
  const startDate = new Date(endDate);
  startDate.setMonth(startDate.getMonth() - 1);
  if (endDate.getDate() < startDate.getDate()) {
    startDate.setDate(0);
  }
  return { startDate, endDate };
};
const { startDate, endDate } = getLastMonthRange();

const INITIAL_FILTERS = {
  order_by: 'id',
  order: 'desc',
  page: 1,
  type: [],
  merchant_id: [],
  status: [],
};

function Transactions() {
  const { isMobileLayout } = useDeviceWidth();
  const [transactions, setTransactions] = useState([]);
  const [pageCount, setPageCount] = useState(NUM_OF_PAGES_DEFAULT);
  const [isLoading, setIsLoading] = useState(false);
  const [merchantList, setMerchantList] = useState([]);
  const filterMethods = useFilter(INITIAL_FILTERS);

  const defaultFilters = {
    order_by: 'id',
    order: 'desc',
    per_page: isMobileLayout ? ITEMS_PER_PAGE_MOBILE : ITEMS_PER_PAGE,
  };

  const PAYLOAD = { without_pagination: true, active: true };

  const addDataToParams = (params) => {
    if (!params?.created_at_from) {
      params.created_at_from = startDate.toISOString().split('T')[0];
      params.created_at_to = endDate.toISOString().split('T')[0];
    }
    return params;
  };

  const getTransactions = async (signal) => {
    try {
      setIsLoading(true);
      let params = {
        ...defaultFilters,
        ...filterMethods.formattedFilters,
      };
      params = addDataToParams(params);
      const res = await listTransactions(params, signal);

      setTransactions(res.data);
      setPageCount(Math.ceil(res.meta.total / ITEMS_PER_PAGE));
      if (!merchantList.length) {
        const merch = await listMerchants(PAYLOAD);
        setMerchantList(merch.data);
      }
    } catch (err) {
      const messages = extractErrorSlug(err?.response);
      toast.error(
        i18n.t([
          `error.transactionHistory.${messages}`,
          'error.transactionHistory.unspecific',
        ]),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formatPdfParams = (obj) => {
    const formattedObject = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (Array.isArray(value) && value.some((item) => item.label)) {
        formattedObject[key] = value.map((item) => item.value);
      }
    });
    return { ...obj, ...formattedObject };
  };

  const downloadTransactions = async () => {
    try {
      let params = formatPdfParams(filterMethods.currentFilters);
      params = addDataToParams(params);

      await downloadTransactionsPdf(params);
    } catch (err) {
      if (!err?.response?.data) {
        toast.error(i18n.t('error.transactionHistory.unspecific'));
        return;
      }
      const decoder = new TextDecoder('utf-8');
      const text = decoder.decode(err?.response?.data);
      err.response.data = JSON.parse(text);
      const messages = extractErrorSlug(err?.response);

      toast.error(
        i18n.t([
          `error.transactionHistory.${messages[0]}`,
          'error.transactionHistory.unspecific',
        ]),
      );
    }
  };

  const changePage = ({ selected }) => {
    filterMethods.setCurrentFilters((state) => ({
      ...state,
      page: selected + 1,
    }));
  };

  useEffect(() => {
    const controller = new AbortController();

    getTransactions(controller.signal);

    return () => controller.abort();
  }, [filterMethods.currentFilters]);

  return (
    <MainTemplate
      headerProps={{
        returnTo: '/wallet',
        title: i18n.t('transactionHistory.mobileTitle'),
        returnAction: filterMethods.showFilter
          ? () => {
              filterMethods.setShowFilter(false);
            }
          : null,
        customButton: !filterMethods.showFilter && (
          <div className={`isMobileLayout ? 'pr-20' : '`}>
            <FilterIcon
              onClick={() => {
                filterMethods.setShowFilter((state) => !state);
              }}
            />
          </div>
        ),
      }}
    >
      <div className="size-full flex justify-around py-10 px-4 sm:px-0">
        {isLoading ? (
          <div
            className={`h-[600px] max-w-[600px] 2xl:max-w-none w-full lg:w-[80%] 2xl:w-[55%] grid place-content-center ${
              !isMobileLayout && filterMethods.showFilter
                ? 'lg:mx-[5%] 2xl:mx-[10%]'
                : ''
            }`}
          >
            <Loading />
          </div>
        ) : (
          (!isMobileLayout ||
            (isMobileLayout && !filterMethods.showFilter)) && (
            <div
              className={`flex flex-col justify-between relative max-w-[600px] 2xl:max-w-none w-full lg:w-[80%] 2xl:w-[55%] ${
                filterMethods.showAnimation
                  ? filterMethods.showFilter
                    ? 'animate-left'
                    : 'animate-right'
                  : ''
              } ${
                !isMobileLayout && filterMethods.showFilter
                  ? 'lg:mx-[5%] 2xl:mx-[10%]'
                  : ''
              }`}
              hideInMobile={filterMethods.showFilter}
            >
              <div className="flex items-center justify-center mb-16">
                <TwTitle
                  text={i18n.t('transactionHistory.title')}
                  size={isMobileLayout ? 'm_lg' : 'd_lg'}
                  font="arboriaBold"
                  classList="text-center"
                />
                <div className="flex items-center justify-end absolute right-2">
                  <button
                    type="button"
                    className="flex items-center cursor-pointer h-10"
                    onClick={() => {
                      downloadTransactions();
                    }}
                  >
                    <DownloadIcon />
                    {!isMobileLayout && (
                      <TwTitle
                        text="PDF"
                        size={isMobileLayout ? 'm_sm' : 'd_sm'}
                        font="arboriaBold"
                        classList="text-left ml-1"
                      />
                    )}
                  </button>
                  {!filterMethods.showFilter && !isMobileLayout && (
                    <button
                      type="button"
                      className="flex items-center cursor-pointer h-10 ml-4"
                      onClick={() => {
                        filterMethods.setShowFilter(true);
                        filterMethods.setShowAnimation(true);
                      }}
                    >
                      <FilterIcon />
                      <TwTitle
                        text={i18n.t('filters.title')}
                        size={isMobileLayout ? 'm_sm' : 'd_sm'}
                        font="arboriaBold"
                        classList="text-left ml-1"
                      />
                    </button>
                  )}
                </div>
              </div>
              <div className="flex bg-black bg-opacity-20 font-bold mb-4 items-center justify-between text-base text-crem min-h-10 p-[5px] w-full">
                <p className="w-32 2xl:w-40 text-left">
                  {i18n.t('transactionHistory.transaction')}
                </p>
                <p className="w-[6.5rem] text-left">
                  {i18n.t('transactionHistory.date')}
                </p>
                <p className="w-20 text-right">
                  {i18n.t('transactionHistory.amount')}
                </p>
                {!isMobileLayout && (
                  <p className="w-[6.5rem] mx-2 pl-4 text-left">
                    {i18n.t('transactionHistory.status')}
                  </p>
                )}
              </div>
              <div
                className={`w-full lg:max-h-[50vh] 2xl:max-h-full overflow-y-auto flex-col flex ${
                  !transactions.length ? 'justify-center' : 'justify-start'
                } flex-grow`}
                id="transactions-list"
              >
                {transactions.map((el) => (
                  <TransactionResume key={el.id} data={el} />
                ))}
                {!isLoading && !transactions.length && (
                  <Paragraph textAlign="center" style={{ margin: '0 auto' }}>
                    {i18n.t(
                      filterMethods.isDirty
                        ? 'filters.emptyData'
                        : 'lastTransactions.noData',
                    )}
                  </Paragraph>
                )}
              </div>
              {Boolean(pageCount) && pageCount > 1 && !isLoading && (
                <PaginationWrapper>
                  <ReactPaginate
                    breakLabel=""
                    nextLabel={<IoIosArrowForward />}
                    previousLabel={<IoIosArrowBack />}
                    disableInitialCallback
                    forcePage={filterMethods.currentFilters.page - 1}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={NUM_OF_PAGES_DEFAULT}
                    activeLinkClassName="current-page"
                    disabledClassName="button-disabled"
                    pageLinkClassName="page-link"
                    previousLinkClassName="prev-button"
                    nextLinkClassName="next-button"
                  />
                </PaginationWrapper>
              )}
            </div>
          )
        )}
        {filterMethods.showFilter && (
          <>
            <SectionDivider className="h-[75%] animate-fade_in" />
            <Filters
              {...filterMethods}
              handleSubmit={getTransactions}
              filtersType="transactionHistory"
              metaData={{
                merchant_id:
                  merchantList.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) || [],
              }}
            />
          </>
        )}
      </div>
    </MainTemplate>
  );
}

export default Transactions;
