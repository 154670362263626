import React from 'react';
import PropTypes from 'prop-types';

import toast from 'react-hot-toast';
import i18n from '@/translate/i18n';

import { createRandomId, extractErrorSlug } from '@/helpers/functions';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import TwDetailsLine from '@/components/v2/atoms/TwDetailsLine';
import { ReactComponent as DownloadIcon } from '@/assets/downloadIcon.svg';
import { downloadTransactionPdf } from '@/services/api';

function TransactionDetailsSummary({
  data,
  bonus,
  hideTitle,
  status = 'default',
}) {
  const { isMobileLayout } = useDeviceWidth();
  const showDownloadButton = status !== 'pending';

  const downloadPdf = async () => {
    const transaction_id = data?.id;
    try {
      await downloadTransactionPdf(transaction_id);
    } catch (err) {
      if (!err?.response?.data) {
        toast.error(i18n.t('error.transactionPdf.unspecific'));
        return;
      }
      const messages = extractErrorSlug(err?.response);
      toast.error(
        i18n.t([
          `error.transactionPdf.${messages}`,
          'error.transactionPdf.unspecific',
        ]),
      );
    }
  };

  return (
    <div className="relative flex flex-col items-center text-white gap-16 mb-[5vh] sm:mb-0 animate-right sm:justify-between sm:max-h-[550px]">
      {!hideTitle && (
        <>
          <TwTitle
            text={i18n.t('transactionDetailsSummary.title')}
            size={isMobileLayout ? 'm_md' : 'd_lg'}
            font="arboriaBold"
            classList="text-center"
          />
          {showDownloadButton && (
            <button
              type="button"
              className="flex items-center cursor-pointer h-10 absolute right-0"
              onClick={() => {
                downloadPdf();
              }}
            >
              <DownloadIcon />
              {!isMobileLayout && (
                <TwTitle
                  text="PDF"
                  size={isMobileLayout ? 'm_sm' : 'd_sm'}
                  font="arboriaBold"
                  classList="text-left ml-1"
                />
              )}
            </button>
          )}
        </>
      )}
      <div className="flex flex-col items-center self-center text-sand-700 gap-4 animate-right">
        {Object.entries(data).map(([key, value]) => {
          if (!value) {
            return null;
          }

          return key.includes('youReceive') ||
            key.includes('youTransferred') ? (
            <TwDetailsLine
              info={`transactionDetailsSummary.${key}`}
              amount={value}
              size="sm"
              key={createRandomId()}
              divider
              colorStatus={status}
            />
          ) : key === 'bonus' ? (
            <TwDetailsLine
              bonus={bonus}
              amount={value}
              size="sm"
              key={createRandomId()}
            />
          ) : (
            <TwDetailsLine
              info={`transactionDetailsSummary.${key}`}
              amount={value}
              size="sm"
              key={createRandomId()}
            />
          );
        })}
      </div>
      <span />
    </div>
  );
}

TransactionDetailsSummary.propTypes = {
  bonus: PropTypes.shape,
  data: PropTypes.shape,
  hideTitle: PropTypes.string,
  status: PropTypes.string,
};

export default TransactionDetailsSummary;
