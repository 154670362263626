import React from 'react';
import PropTypes from 'prop-types';

function TwButton({
  handleClick,
  isSubmitting,
  disabled,
  font = 'arboria-regular',
  label,
  height = 'primary',
  classList = '',
  ...rest
}) {
  const sizes = {
    primary: 'h-16 rounded-lg md:text-lg',
    secondary: 'h-10 rounded md:text-base',
  };

  return (
    <button
      type="submit"
      {...rest}
      handleClick={handleClick}
      disabled={disabled || isSubmitting}
      className={`w-full text-xl font-${font} ${sizes[height]} ${
        !disabled ? 'cursor-pointer' : 'cursor-not-allowed'
      } ${
        !disabled ? 'bg-grass-800' : 'bg-ashes-700'
      } text-sm font-${font} ${classList}`}
    >
      {label}
    </button>
  );
}

TwButton.propTypes = {
  label: PropTypes.string,
  font: PropTypes.string,
  handleClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  classList: PropTypes.string,
};

export default TwButton;
